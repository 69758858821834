import { Box } from '@mui/material';

import { Visit } from 'api/visit';
import SingleDirectionProvider from 'common/providers/SingleDirectionProvider';
import { LoggerService } from 'common/services/logger';

// eslint-disable-next-line import/prefer-default-export
export const getHighlightedText = (text: string, searchTerm: string) => {
	const splitText = text.split(new RegExp(`(${searchTerm})`, 'gi'));

	return (
		<SingleDirectionProvider dir="ltr">
			{splitText
				.filter((str) => !!str)
				.map((str, index) => (
					<Box
						key={index}
						component="span"
						sx={{
							...(str.toLowerCase() !== searchTerm.toLowerCase() && { fontWeight: (theme) => theme.typography.body1.fontWeight })
						}}
					>
						{str}
					</Box>
				))}
		</SingleDirectionProvider>
	);
};

export const getDataTestId = (symptom: string) => `symptom-${symptom.trim().toLowerCase().replace(/ /g, '-')}`;

export function getVisitSex(visit: Visit) {
	if (visit.sex) {
		return visit.sex;
	}
	if (visit.gender) {
		LoggerService.warning(`Sex is undefined but gender is valid. Gender: ${visit.gender}. Visit id: ${visit.id}`);
		return visit.gender;
	}
	LoggerService.error(`Both sex and gender are undefined in visit response. Visit id: ${visit.id}`);
}
