import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { VISIT_ALREADY_FINISHED_STATUS_CODE } from 'common/configs/queryClient';
import { pqApi, searchApi } from 'common/services/http';
import { LoggerService } from 'common/services/logger';

export interface SelectSymptomResponse {
	body_part_id: number;
	clinical_entity_id: number;
	clinical_entity_type: string;
	id: number;
	is_implicit: boolean;
	is_left: boolean;
	main_symptom: boolean;
	symptom_id: number;
	visit_id: number;
}

export interface Symptom {
	id: number;
	name: string;
	references: string;
	is_locked: boolean;
	sex: string;
	order?: any;
	general: boolean;
	is_body_localized?: boolean;
	is_left?: any;
	visit_symptom_id?: any;
	body_parts: number[];
	medical_title: string;
	title: string;
	description: string;
	selected: boolean;
	body_part_id: number;
}

export interface SearchSymptomResponse {
	symptom: Symptom;
	matching_synonym: string;
}

export type SymptomSearchAutocompleteResponse = string[];

export type Sex = 'M' | 'F' | 'ALL';

const selectSymptom = async (visitId: string, symptomId: number) => {
	const { data } = await pqApi.post<SelectSymptomResponse>(`v2/visits/${visitId}/symptoms/${symptomId}`);
	return data;
};

export const useSelectSymptom = (visitId: string) => {
	return useMutation('select-symptom', (symptomId: number) => selectSymptom(visitId, symptomId), {
		onError: (error: AxiosError) => LoggerService.error(`failed to select symptom for visit id: ${visitId}`, { error })
	});
};

const getSymptomSearchAutocomplete = async (searchTerm: string, sex: Sex) => {
	const { data } = await searchApi.get<SymptomSearchAutocompleteResponse>(`symptoms/autocomplete?search_term=${searchTerm}&sex=${sex}`);
	return data;
};

export const useGetSymptomSearchAutocomplete = (searchTerm: string, sex: Sex) => {
	return useQuery(['symptom-autocomplete', searchTerm], () => getSymptomSearchAutocomplete(searchTerm, sex), {
		keepPreviousData: true,
		enabled: !!sex
	});
};

const getSymptomSearchResults = async (visitId: string, searchTerm: string, sex: Sex) => {
	const { data } = await searchApi.get<SearchSymptomResponse[]>(`symptoms/search?search_term=${searchTerm}&sex=${sex}`);
	return data;
};

const getAllSymptoms = async (visitId: string) => {
	const { data } = await pqApi.get<Symptom[]>(`visits/${visitId}/symptoms?having_questions_only=true`);
	return data;
};

export const useGetSymptomSearchResults = (visitId: string, searchTerm: string, sex: Sex) => {
	return useQuery(['symptom-search', searchTerm], () => getSymptomSearchResults(visitId, searchTerm, sex), { enabled: false });
};

export const useGetAllSymptoms = (visitId: string) => {
	return useQuery(['all-symptoms'], () => getAllSymptoms(visitId), {
		onError: (error: AxiosError) => LoggerService.error(`failed to get all symptoms`, { error })
	});
};

const getSelectedSymptom = async (visitId: string) => {
	const { data } = await pqApi.get<Symptom[]>(`visits/${visitId}/symptoms/selected`, { params: { 'skip-finish-validation': true } });
	return data;
};

export const useGetSelectedSymptom = (visitId: string, enabled: boolean) => {
	return useQuery(['selected-symptom', visitId], () => getSelectedSymptom(visitId), {
		enabled,
		onError: (error: AxiosError) => {
			if (error.response?.status !== VISIT_ALREADY_FINISHED_STATUS_CODE) {
				LoggerService.error(`failed to get selected symptom for visit id ${visitId}`, { error });
			}
		}
	});
};
